import { extendTheme } from '@chakra-ui/react';
import { Button } from './components/Button';

const theme = extendTheme({
  components: {
    Button,
  },
  colors: {
    brand: {
      50: '#E6F6FF',
      100: '#BAE3FF',
      200: '#7CC4FA',
      300: '#47A3F3',
      400: '#2186EB',
      500: '#0967D2',
      600: '#0552B5',
      700: '#03449E',
      800: '#01337D',
      900: '#002159',
    },
    facebook: {
      500: '#1877F2',
      600: '#1664d9',
    },
    twitter: {
      500: '#1DA1F2',
      600: '#1a91da',
    },
    linkedin: {
      500: '#0A66C2',
      600: '#0959ab',
    },
    whatsapp: {
      500: '#25D366',
      600: '#21bd5a',
    },
    telegram: {
      500: '#0088cc',
      600: '#0077b3',
    },
  },
});

export default theme;
