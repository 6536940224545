import React from 'react';
import { Button, VStack, Text } from '@chakra-ui/react';
import * as PhosphorIcons from "@phosphor-icons/react";

const ModernIconButton = ({ 
  icon,
  label,
  onClick,
  variant = 'solid',
  size = 'md',
  colorScheme = 'blue',
  isDisabled,
  weight = 'regular',
  ...props 
}) => {
  // Debug logs améliorés
  console.log("ModernIconButton - Props reçus:", { icon, label, size, weight });
  
  // Obtenir le composant d'icône depuis PhosphorIcons
  const IconComponent = PhosphorIcons[icon];
  
  // Debug log pour l'icône
  console.log("ModernIconButton - IconComponent trouvé:", !!IconComponent, "pour l'icône:", icon);

  if (!IconComponent) {
    console.warn(`Icon "${icon}" not found in PhosphorIcons. Available icons:`, Object.keys(PhosphorIcons));
    return (
      <Button
        onClick={onClick}
        variant={variant}
        size={size}
        colorScheme={colorScheme}
        isDisabled={isDisabled}
        {...props}
      >
        <VStack spacing={1}>
          <Text>⚠️</Text>
          {label && <Text>{label}</Text>}
        </VStack>
      </Button>
    );
  }

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Button
      onClick={onClick}
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
      sx={buttonStyle}
      {...props}
    >
      <VStack spacing={1}>
        <IconComponent 
          size={24} 
          weight={weight}
        />
        {label && <Text fontSize="sm">{label}</Text>}
      </VStack>
    </Button>
  );
};

export default ModernIconButton;
