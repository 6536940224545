import { 
  Box, 
  VStack, 
  Heading, 
  Divider, 
  useToast,
  SimpleGrid,
  Text,
  Container,
  Badge,
  HStack,
  Switch,
} from '@chakra-ui/react';
import EmojiButton from '../components/common/EmojiButton';
import { useState } from 'react';

const TestEmojiButtons = () => {
  const toast = useToast();
  const [showLabels, setShowLabels] = useState(true);

  const handleClick = (message) => {
    toast({
      title: "Action",
      description: message,
      status: "info",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <Box textAlign="center">
          <Heading size="lg">Galerie des Boutons Emoji</Heading>
          <Text color="gray.600" mt={2}>Une collection complète de boutons expressifs</Text>
          <HStack justify="center" mt={4}>
            <Text>Afficher les labels</Text>
            <Switch isChecked={showLabels} onChange={(e) => setShowLabels(e.target.checked)} />
          </HStack>
        </Box>

        <VStack spacing={4} align="stretch">
          <Heading size="md">Actions Principales</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="🚗"
              label={showLabels ? "Créer un trajet" : ""}
              onClick={() => handleClick("Créer un trajet")}
              colorScheme="green"
              size="lg"
            />
            <EmojiButton
              emoji="🔍"
              label={showLabels ? "Rechercher" : ""}
              onClick={() => handleClick("Rechercher")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="🏠"
              label={showLabels ? "Accueil" : ""}
              onClick={() => handleClick("Accueil")}
              colorScheme="teal"
              size="lg"
            />
            <EmojiButton
              emoji="👤"
              label={showLabels ? "Profil" : ""}
              onClick={() => handleClick("Profil")}
              colorScheme="purple"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Transport et Destinations</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="✈️"
              label={showLabels ? "Aéroport" : ""}
              onClick={() => handleClick("Aéroport")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="🚉"
              label={showLabels ? "Gare" : ""}
              onClick={() => handleClick("Gare")}
              colorScheme="red"
              size="lg"
            />
            <EmojiButton
              emoji="🚕"
              label={showLabels ? "Taxi" : ""}
              onClick={() => handleClick("Taxi")}
              colorScheme="yellow"
              size="lg"
            />
            <EmojiButton
              emoji="📍"
              label={showLabels ? "Station" : ""}
              onClick={() => handleClick("Station")}
              colorScheme="red"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Communication</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="💭"
              label={showLabels ? "Messages" : ""}
              onClick={() => handleClick("Messages")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="🔔"
              label={showLabels ? "Notifications" : ""}
              onClick={() => handleClick("Notifications")}
              colorScheme="yellow"
              size="lg"
            />
            <EmojiButton
              emoji="📱"
              label={showLabels ? "Partager" : ""}
              onClick={() => handleClick("Partager")}
              colorScheme="green"
              size="lg"
            />
            <EmojiButton
              emoji="📨"
              label={showLabels ? "Inviter" : ""}
              onClick={() => handleClick("Inviter")}
              colorScheme="blue"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Réservation et Paiement</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="📅"
              label={showLabels ? "Date" : ""}
              onClick={() => handleClick("Date")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="⌚"
              label={showLabels ? "Heure" : ""}
              onClick={() => handleClick("Heure")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="👥"
              label={showLabels ? "Passagers" : ""}
              onClick={() => handleClick("Passagers")}
              colorScheme="teal"
              size="lg"
            />
            <EmojiButton
              emoji="💶"
              label={showLabels ? "Paiement" : ""}
              onClick={() => handleClick("Paiement")}
              colorScheme="green"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">États et Statuts</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="✅"
              label={showLabels ? "Confirmé" : ""}
              onClick={() => handleClick("Confirmé")}
              colorScheme="green"
              size="lg"
            />
            <EmojiButton
              emoji="⏳"
              label={showLabels ? "En attente" : ""}
              onClick={() => handleClick("En attente")}
              colorScheme="yellow"
              size="lg"
            />
            <EmojiButton
              emoji="❌"
              label={showLabels ? "Annulé" : ""}
              onClick={() => handleClick("Annulé")}
              colorScheme="red"
              size="lg"
            />
            <EmojiButton
              emoji="🎯"
              label={showLabels ? "En cours" : ""}
              onClick={() => handleClick("En cours")}
              colorScheme="blue"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Actions Rapides</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="📝"
              label={showLabels ? "Modifier" : ""}
              onClick={() => handleClick("Modifier")}
              colorScheme="blue"
              variant="outline"
              size="lg"
            />
            <EmojiButton
              emoji="🗑️"
              label={showLabels ? "Supprimer" : ""}
              onClick={() => handleClick("Supprimer")}
              colorScheme="red"
              variant="outline"
              size="lg"
            />
            <EmojiButton
              emoji="⭐"
              label={showLabels ? "Favoris" : ""}
              onClick={() => handleClick("Favoris")}
              colorScheme="yellow"
              variant="outline"
              size="lg"
            />
            <EmojiButton
              emoji="📋"
              label={showLabels ? "Copier" : ""}
              onClick={() => handleClick("Copier")}
              colorScheme="gray"
              variant="outline"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Paramètres et Support</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="⚙️"
              label={showLabels ? "Paramètres" : ""}
              onClick={() => handleClick("Paramètres")}
              colorScheme="gray"
              size="lg"
            />
            <EmojiButton
              emoji="🔒"
              label={showLabels ? "Sécurité" : ""}
              onClick={() => handleClick("Sécurité")}
              colorScheme="red"
              size="lg"
            />
            <EmojiButton
              emoji="❓"
              label={showLabels ? "Aide" : ""}
              onClick={() => handleClick("Aide")}
              colorScheme="teal"
              size="lg"
            />
            <EmojiButton
              emoji="📞"
              label={showLabels ? "Contact" : ""}
              onClick={() => handleClick("Contact")}
              colorScheme="blue"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Nouveaux Boutons Proposés</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <EmojiButton
              emoji="🎫"
              label={showLabels ? "Réservations" : ""}
              onClick={() => handleClick("Réservations")}
              colorScheme="purple"
              size="lg"
            />
            <EmojiButton
              emoji="📊"
              label={showLabels ? "Statistiques" : ""}
              onClick={() => handleClick("Statistiques")}
              colorScheme="cyan"
              size="lg"
            />
            <EmojiButton
              emoji="🌍"
              label={showLabels ? "Carte" : ""}
              onClick={() => handleClick("Carte")}
              colorScheme="green"
              size="lg"
            />
            <EmojiButton
              emoji="🔄"
              label={showLabels ? "Actualiser" : ""}
              onClick={() => handleClick("Actualiser")}
              colorScheme="blue"
              size="lg"
            />
            <EmojiButton
              emoji="📢"
              label={showLabels ? "Annonces" : ""}
              onClick={() => handleClick("Annonces")}
              colorScheme="orange"
              size="lg"
            />
            <EmojiButton
              emoji="🎁"
              label={showLabels ? "Offres" : ""}
              onClick={() => handleClick("Offres")}
              colorScheme="pink"
              size="lg"
            />
            <EmojiButton
              emoji="📱"
              label={showLabels ? "Application" : ""}
              onClick={() => handleClick("Application")}
              colorScheme="gray"
              size="lg"
            />
            <EmojiButton
              emoji="🌙"
              label={showLabels ? "Mode Nuit" : ""}
              onClick={() => handleClick("Mode Nuit")}
              colorScheme="purple"
              size="lg"
            />
          </SimpleGrid>
        </VStack>

      </VStack>
    </Container>
  );
};

export default TestEmojiButtons;
