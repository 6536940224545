export const Button = {
  baseStyle: {
    fontWeight: "medium",
    borderRadius: "lg",
    _hover: {
      transform: "translateY(-1px)",
      boxShadow: "md",
    },
    _active: {
      transform: "translateY(0)",
      boxShadow: "sm",
    },
  },
  variants: {
    solid: {
      bg: "brand.500",
      color: "white",
      _hover: {
        bg: "brand.600",
      },
    },
    outline: {
      borderWidth: "2px",
    },
    facebook: {
      bg: "#1877F2",
      color: "white",
      _hover: { 
        bg: "#1664d9",
        color: "white" 
      },
    },
    twitter: {
      bg: "#1DA1F2",
      color: "white",
      _hover: { 
        bg: "#1a91da",
        color: "white" 
      },
    },
    linkedin: {
      bg: "#0A66C2",
      color: "white",
      _hover: { 
        bg: "#0959ab",
        color: "white" 
      },
    },
    whatsapp: {
      bg: "#25D366",
      color: "white",
      _hover: { 
        bg: "#21bd5a",
        color: "white" 
      },
    },
    telegram: {
      bg: "#0088cc",
      color: "white",
      _hover: { 
        bg: "#0077b3",
        color: "white" 
      },
    },
  },
  sizes: {
    lg: {
      fontSize: "md",
      px: 6,
      py: 4,
    },
    md: {
      fontSize: "sm",
      px: 4,
      py: 3,
    },
    sm: {
      fontSize: "sm",
      px: 3,
      py: 2,
    },
  },
  defaultProps: {
    variant: "solid",
    size: "md",
    colorScheme: "blue",
  },
};
