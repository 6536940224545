import { 
  Box, 
  VStack, 
  Heading, 
  Divider, 
  useToast,
  SimpleGrid,
  Text,
  Container,
  HStack,
  Switch,
  Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import ModernIconButton from '../components/common/ModernIconButton';

const TestModernButtons = () => {
  const toast = useToast();
  const [showLabels, setShowLabels] = useState(true);
  const [iconWeight, setIconWeight] = useState('regular');

  const handleClick = (message) => {
    toast({
      title: "Action",
      description: message,
      status: "info",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        <Box textAlign="center">
          <Heading size="lg">Galerie des Boutons Modernes</Heading>
          <Text color="gray.600" mt={2}>Collection de boutons avec icônes Phosphor</Text>
          <HStack justify="center" mt={4} spacing={8}>
            <HStack>
              <Text>Labels</Text>
              <Switch isChecked={showLabels} onChange={(e) => setShowLabels(e.target.checked)} />
            </HStack>
            <HStack>
              <Text>Style</Text>
              <Select value={iconWeight} onChange={(e) => setIconWeight(e.target.value)} size="sm" w="120px">
                <option value="thin">Fin</option>
                <option value="light">Léger</option>
                <option value="regular">Normal</option>
                <option value="bold">Gras</option>
                <option value="fill">Rempli</option>
                <option value="duotone">Duo-ton</option>
              </Select>
            </HStack>
          </HStack>
        </Box>

        <VStack spacing={4} align="stretch">
          <Heading size="md">Navigation</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="House"
              label={showLabels ? "Accueil" : ""}
              onClick={() => handleClick("Accueil")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="MagnifyingGlass"
              label={showLabels ? "Rechercher" : ""}
              onClick={() => handleClick("Rechercher")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="User"
              label={showLabels ? "Profil" : ""}
              onClick={() => handleClick("Profil")}
              colorScheme="purple"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Gear"
              label={showLabels ? "Paramètres" : ""}
              onClick={() => handleClick("Paramètres")}
              colorScheme="gray"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Transport</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="Car"
              label={showLabels ? "Créer trajet" : ""}
              onClick={() => handleClick("Créer trajet")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Airplane"
              label={showLabels ? "Aéroport" : ""}
              onClick={() => handleClick("Aéroport")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Train"
              label={showLabels ? "Gare" : ""}
              onClick={() => handleClick("Gare")}
              colorScheme="red"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="MapPin"
              label={showLabels ? "Station" : ""}
              onClick={() => handleClick("Station")}
              colorScheme="red"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Communication</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="ChatCircle"
              label={showLabels ? "Messages" : ""}
              onClick={() => handleClick("Messages")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Bell"
              label={showLabels ? "Notifications" : ""}
              onClick={() => handleClick("Notifications")}
              colorScheme="yellow"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="ShareNetwork"
              label={showLabels ? "Partager" : ""}
              onClick={() => handleClick("Partager")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="EnvelopeSimple"
              label={showLabels ? "Email" : ""}
              onClick={() => handleClick("Email")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Réservation</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="Calendar"
              label={showLabels ? "Date" : ""}
              onClick={() => handleClick("Date")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Clock"
              label={showLabels ? "Heure" : ""}
              onClick={() => handleClick("Heure")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Users"
              label={showLabels ? "Passagers" : ""}
              onClick={() => handleClick("Passagers")}
              colorScheme="teal"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="CurrencyEur"
              label={showLabels ? "Paiement" : ""}
              onClick={() => handleClick("Paiement")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Actions</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="PencilSimple"
              label={showLabels ? "Modifier" : ""}
              onClick={() => handleClick("Modifier")}
              colorScheme="blue"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Trash"
              label={showLabels ? "Supprimer" : ""}
              onClick={() => handleClick("Supprimer")}
              colorScheme="red"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Star"
              label={showLabels ? "Favoris" : ""}
              onClick={() => handleClick("Favoris")}
              colorScheme="yellow"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Copy"
              label={showLabels ? "Copier" : ""}
              onClick={() => handleClick("Copier")}
              colorScheme="gray"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Statuts</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="Check"
              label={showLabels ? "Confirmé" : ""}
              onClick={() => handleClick("Confirmé")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Clock"
              label={showLabels ? "En attente" : ""}
              onClick={() => handleClick("En attente")}
              colorScheme="yellow"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="X"
              label={showLabels ? "Annulé" : ""}
              onClick={() => handleClick("Annulé")}
              colorScheme="red"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Target"
              label={showLabels ? "En cours" : ""}
              onClick={() => handleClick("En cours")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Arrondissements et Stations</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="MapPin"
              label={showLabels ? "Rive Droite" : ""}
              onClick={() => handleClick("Rive Droite")}
              colorScheme="purple"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="MapTrifold"
              label={showLabels ? "Rive Gauche" : ""}
              onClick={() => handleClick("Rive Gauche")}
              colorScheme="purple"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Train"
              label={showLabels ? "Gare du Nord" : ""}
              onClick={() => handleClick("Gare du Nord")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Airplane"
              label={showLabels ? "CDG" : ""}
              onClick={() => handleClick("CDG")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Buildings"
              label={showLabels ? "La Défense" : ""}
              onClick={() => handleClick("La Défense")}
              colorScheme="gray"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Tree"
              label={showLabels ? "Bois de Boulogne" : ""}
              onClick={() => handleClick("Bois de Boulogne")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Bank"
              label={showLabels ? "Opéra" : ""}
              onClick={() => handleClick("Opéra")}
              colorScheme="yellow"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="ShoppingBag"
              label={showLabels ? "Champs-Élysées" : ""}
              onClick={() => handleClick("Champs-Élysées")}
              colorScheme="pink"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Lighthouse"
              label={showLabels ? "Tour Eiffel" : ""}
              onClick={() => handleClick("Tour Eiffel")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Books"
              label={showLabels ? "Quartier Latin" : ""}
              onClick={() => handleClick("Quartier Latin")}
              colorScheme="orange"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Heart"
              label={showLabels ? "Montmartre" : ""}
              onClick={() => handleClick("Montmartre")}
              colorScheme="red"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="TreePalm"
              label={showLabels ? "Jardin des Plantes" : ""}
              onClick={() => handleClick("Jardin des Plantes")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Arrondissements</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            {[...Array(20)].map((_, i) => (
              <ModernIconButton
                key={i}
                icon="MapPinLine"
                label={showLabels ? `${i + 1}${i === 0 ? 'er' : 'ème'}` : ""}
                onClick={() => handleClick(`${i + 1}${i === 0 ? 'er' : 'ème'} arrondissement`)}
                colorScheme={i % 2 === 0 ? "purple" : "blue"}
                size="lg"
                weight={iconWeight}
              />
            ))}
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Actions</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="PencilSimple"
              label={showLabels ? "Modifier" : ""}
              onClick={() => handleClick("Modifier")}
              colorScheme="blue"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Trash"
              label={showLabels ? "Supprimer" : ""}
              onClick={() => handleClick("Supprimer")}
              colorScheme="red"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Star"
              label={showLabels ? "Favoris" : ""}
              onClick={() => handleClick("Favoris")}
              colorScheme="yellow"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Copy"
              label={showLabels ? "Copier" : ""}
              onClick={() => handleClick("Copier")}
              colorScheme="gray"
              variant="outline"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

        <Divider />

        <VStack spacing={4} align="stretch">
          <Heading size="md">Statuts</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            <ModernIconButton
              icon="Check"
              label={showLabels ? "Confirmé" : ""}
              onClick={() => handleClick("Confirmé")}
              colorScheme="green"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Clock"
              label={showLabels ? "En attente" : ""}
              onClick={() => handleClick("En attente")}
              colorScheme="yellow"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="X"
              label={showLabels ? "Annulé" : ""}
              onClick={() => handleClick("Annulé")}
              colorScheme="red"
              size="lg"
              weight={iconWeight}
            />
            <ModernIconButton
              icon="Target"
              label={showLabels ? "En cours" : ""}
              onClick={() => handleClick("En cours")}
              colorScheme="blue"
              size="lg"
              weight={iconWeight}
            />
          </SimpleGrid>
        </VStack>

      </VStack>
    </Container>
  );
};

export default TestModernButtons;
