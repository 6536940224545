import React, { useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  useToast,
  Text,
  SimpleGrid,
  Box,
  useColorModeValue,
  Center,
  Spinner,
  useClipboard,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import ModernIconButton from './ModernIconButton';
import { calculatePricePerPerson, AIRPORT_PRICES } from '../../config/prices';
import { formatStation } from '../../utils/formatters';
import * as PhosphorIcons from "@phosphor-icons/react";

// Constantes pour les couleurs des réseaux sociaux
const socialColors = {
  facebook: {
    bg: '#1877F2',
    hover: '#1664d9'
  },
  twitter: {
    bg: '#1DA1F2',
    hover: '#1a91da'
  },
  linkedin: {
    bg: '#0A66C2',
    hover: '#0959ab'
  },
  whatsapp: {
    bg: '#25D366',
    hover: '#21bd5a'
  },
  telegram: {
    bg: '#0088cc',
    hover: '#0077b3'
  },
  email: {
    bg: '#718096',
    hover: '#4A5568'
  }
};

const ShareModal = ({ isOpen, onClose, ride }) => {
  // Temporairement, pour debug
  console.log("Icônes disponibles:", 
    Object.keys(PhosphorIcons).filter(key => 
      key.includes('Logo') || 
      key === 'Envelope' || 
      key === 'X' || 
      key === 'Linkedin'
    )
  );

  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState(0);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const infoBg = useColorModeValue('blue.50', 'blue.900');
  const infoBorderColor = useColorModeValue('blue.200', 'blue.700');
  const inputBg = useColorModeValue('gray.50', 'gray.700');

  const shareUrl = `https://cotaxiage.app/ride/${ride?.id}`;
  const { hasCopied, onCopy } = useClipboard(shareUrl);

  const generateShareText = useCallback((platform = 'default') => {
    if (!ride) return '';
    
    try {
      const date = new Date(ride?.date);
      const formattedDate = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      const formattedTime = date.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit'
      });

      const currentPassengers = ride?.participants?.length || 1;
      const totalPassengersIfJoined = currentPassengers + 1;

      const isDestinationAirport = ride?.type === 'toAirport';
      const airport = isDestinationAirport ? ride?.destination : ride?.departure;
      const district = 'RIVE_DROITE';

      const basePrice = AIRPORT_PRICES[airport][district];
      const myPrice = Math.ceil(basePrice / totalPassengersIfJoined);
      const savings = basePrice - myPrice;
      const savingsPercentage = Math.round((savings / basePrice) * 100);

      const baseText = `🚖 Partagez un taxi et économisez gros avec CoTaxiage !\n\n` +
        `📍 Départ : ${formatStation(ride?.departure)}\n` +
        `✈️ Arrivée : ${formatStation(ride?.destination)}\n` +
        `📅 ${formattedDate} à ${formattedTime}\n` +
        `🎟️ Places restantes : ${ride?.availableSeats}/4\n` +
        `💰 Prix si vous rejoignez : ${myPrice}€ (au lieu de ${basePrice}€)\n` +
        `✨ Économie : ${savings}€ (${savingsPercentage}%)\n` +
        `👉 Rejoignez mon trajet : ${shareUrl}\n` +
        `🌟 CoTaxiage, 100% gratuit, rapide et éco-friendly. Essayez dès aujourd'hui !\n\n`;

      return baseText;
    } catch (error) {
      console.error('Erreur lors de la génération du texte:', error);
      return 'Erreur lors de la génération du message de partage';
    }
  }, [ride]);

  const handleSocialShare = (platform) => {
    console.log(`Attempting to share via ${platform}`);
    console.log('Icon component being used:', PhosphorIcons[`${platform}Logo`]);
    const shareText = generateShareText(platform);
    console.log('Share text:', shareText);
    
    let shareUrl;
    switch (platform.toLowerCase()) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Partagez un trajet sur CoTaxiage&body=${encodeURIComponent(shareText)}`;
        break;
      default:
        navigator.clipboard.writeText(shareText);
        toast({
          title: "Texte copié !",
          description: "Le message a été copié dans votre presse-papier.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        return;
    }
    window.open(shareUrl, '_blank');
  };

  const handleShare = (platform) => {
    handleSocialShare(platform);
  };

  if (!ride) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent bg={bgColor}>
          <ModalHeader>Partager ce trajet</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Center py={8}>
              <VStack spacing={4}>
                <Spinner size="xl" />
                <Text>Chargement des informations du trajet...</Text>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent bg={bgColor}>
        <ModalHeader>Partager ce trajet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs isFitted variant="enclosed" index={selectedTab} onChange={setSelectedTab}>
            <TabList mb="1em">
              <Tab>Réseaux sociaux</Tab>
              <Tab>Code QR</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4} align="stretch">
                  <SimpleGrid columns={[2, 3, 4]} spacing={4}>
                    <ModernIconButton
                      onClick={() => handleShare('facebook')}
                      icon="FacebookLogo"
                      label="Facebook"
                      variant="facebook"
                      w="full"
                      size="lg"
                    />
                    <ModernIconButton
                      onClick={() => handleShare('twitter')}
                      icon="TwitterLogo"
                      label="Twitter"
                      variant="twitter"
                      w="full"
                      size="lg"
                    />
                    <ModernIconButton
                      onClick={() => handleShare('linkedin')}
                      icon="LinkedinLogo"
                      label="LinkedIn"
                      variant="linkedin"
                      w="full"
                      size="lg"
                    />
                    <ModernIconButton
                      onClick={() => handleShare('whatsapp')}
                      icon="WhatsappLogo"
                      label="WhatsApp"
                      variant="whatsapp"
                      w="full"
                      size="lg"
                    />
                    <ModernIconButton
                      onClick={() => handleShare('telegram')}
                      icon="TelegramLogo"
                      label="Telegram"
                      variant="telegram"
                      w="full"
                      size="lg"
                    />
                    <ModernIconButton
                      onClick={() => handleShare('email')}
                      icon="Envelope"
                      label="Email"
                      variant="solid"
                      colorScheme="gray"
                      w="full"
                      size="lg"
                    />
                  </SimpleGrid>

                  <Box p={4} bg={infoBg} borderRadius="md" borderWidth={1} borderColor={infoBorderColor}>
                    <Text whiteSpace="pre-wrap" fontSize="sm">
                      {generateShareText()}
                    </Text>
                  </Box>

                  <Button onClick={() => handleShare('copy')} width="100%" colorScheme="blue">
                    Copier le message
                  </Button>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={4}>
                  <Box
                    p={4}
                    bg="white"
                    borderRadius="lg"
                    boxShadow="md"
                  >
                    <QRCodeSVG
                      value={shareUrl}
                      size={256}
                      level="H"
                      includeMargin={true}
                    />
                  </Box>
                  <Text fontSize="sm" color="gray.600">
                    Scannez ce code QR pour accéder directement au trajet
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
