import {
  Box,
  VStack,
  Heading,
  Text,
  Container,
  UnorderedList,
  ListItem,
  Divider,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl" mb={6}>
          Politique de Confidentialité
        </Heading>

        <Text>
          Chez CoTaxiage.com, la protection de votre vie privée est une priorité. Cette Politique de Confidentialité a pour but de vous informer sur la manière dont nous collectons, utilisons, partageons et protégeons vos données personnelles, conformément à la législation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD) et la loi française.
        </Text>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            1. Responsable du traitement des données
          </Heading>
          <Text>
            Le responsable du traitement des données personnelles est :<br />
            <strong>Soudane Chokri</strong><br />
            Adresse : Rue Ferdinand Chartier, Saint-Cloud, Hauts-de-Seine (92)<br />
            Contact : support@cotaxiage.com
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            2. Données collectées
          </Heading>
          <Text mb={2}>
            Nous collectons uniquement les données nécessaires au fonctionnement du service et à l'amélioration de votre expérience sur CoTaxiage.com. Ces données peuvent inclure :
          </Text>
          <UnorderedList pl={4} spacing={2}>
            <ListItem><strong>Données d'identification</strong> : nom, prénom, adresse e-mail, numéro de téléphone.</ListItem>
            <ListItem><strong>Données de connexion</strong> : adresse IP, type de navigateur, pages consultées, durée de navigation.</ListItem>
            <ListItem><strong>Données fournies volontairement</strong> : messages envoyés via le formulaire de contact ou tout autre contenu partagé sur le Site.</ListItem>
          </UnorderedList>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            3. Finalités du traitement des données
          </Heading>
          <Text mb={2}>Vos données personnelles sont utilisées uniquement dans les cas suivants :</Text>
          <UnorderedList pl={4} spacing={2}>
            <ListItem>Gestion des communications et des demandes envoyées via le Site.</ListItem>
            <ListItem>Amélioration de l'expérience utilisateur (analyse des comportements de navigation, ajustements du contenu).</ListItem>
            <ListItem>Respect des obligations légales et réglementaires.</ListItem>
          </UnorderedList>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            4. Vos droits
          </Heading>
          <Text mb={2}>Conformément au RGPD, vous disposez des droits suivants sur vos données personnelles :</Text>
          <UnorderedList pl={4} spacing={2}>
            <ListItem><strong>Droit d'accès</strong> : obtenir des informations sur les données que nous détenons.</ListItem>
            <ListItem><strong>Droit de rectification</strong> : corriger les données inexactes ou incomplètes.</ListItem>
            <ListItem><strong>Droit à l'effacement</strong> : demander la suppression de vos données (sauf obligation légale).</ListItem>
            <ListItem><strong>Droit d'opposition</strong> : vous opposer au traitement de vos données dans certains cas.</ListItem>
            <ListItem><strong>Droit à la portabilité</strong> : recevoir vos données dans un format structuré et transférable.</ListItem>
            <ListItem><strong>Droit de retrait de consentement</strong> : retirer votre consentement à tout moment pour les traitements basés sur celui-ci.</ListItem>
          </UnorderedList>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            5. Contact
          </Heading>
          <Text>
            Pour toute question ou demande concernant vos données personnelles, vous pouvez nous contacter à l'adresse suivante : support@cotaxiage.com
          </Text>
        </Box>

        <Divider my={6} />

        <Text color="gray.600" fontSize="sm">
          Dernière mise à jour : 08 Décembre 2024
        </Text>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicy;
