import { Button, Text, HStack } from '@chakra-ui/react';

const EmojiButton = ({ 
  emoji, 
  label, 
  variant = 'solid', 
  size = 'md',
  colorScheme = 'blue',
  onClick,
  isDisabled,
  ...props 
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
      {...props}
    >
      <HStack spacing={2} align="center">
        <Text fontSize={size === 'lg' ? 'xl' : 'md'}>
          {emoji}
        </Text>
        {label && (
          <Text>
            {label}
          </Text>
        )}
      </HStack>
    </Button>
  );
};

// Exemples de boutons prédéfinis
export const CreateRideButton = (props) => (
  <EmojiButton
    emoji="🚙"
    label="Créer un trajet"
    size="lg"
    colorScheme="blue"
    w="100%"
    py={7}
    px={8}
    fontSize="lg"
    fontWeight="bold"
    _hover={{
      transform: 'translateY(-2px)',
      shadow: 'lg',
    }}
    {...props}
  />
);

export const SearchButton = (props) => (
  <EmojiButton
    emoji="🔍"
    label="Rechercher"
    variant="ghost"
    size="lg"
    {...props}
  />
);

export const StationButton = (props) => (
  <EmojiButton
    emoji="🚏"
    label={props.label || "Sélectionner une station"}
    variant="outline"
    size="lg"
    w="100%"
    justifyContent="flex-start"
    {...props}
  />
);

export const ShareButton = (props) => (
  <EmojiButton
    emoji="📤"
    label="Partager"
    size="md"
    colorScheme="green"
    {...props}
  />
);

export const MessageButton = (props) => (
  <EmojiButton
    emoji="💬"
    label={props.label || "Messages"}
    variant="ghost"
    size="md"
    {...props}
  />
);

export default EmojiButton;
